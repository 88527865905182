/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useRef } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { createBrowserHistory } from 'history';
import { Buffer } from 'buffer';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import { StatusBar } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { Provider } from 'react-redux';
import { IonReactRouter } from '@ionic/react-router';

/* Theme variables */
import './theme/variables.css';

import store from './redux/store';

import { deleteItemFromInternalStorage, getInternalStorage, setInternalStorage, info } from './helpers/common';
import _ from 'lodash';
import SharedService, { sharedService } from './services/sharedService';
import { LocalStorageKeys, NavigationRoutes, NetworkStatus, SessionStorageKeys } from './services/enumService';
import { mainRoutes } from './services/navigationService';
import CommonView from './pages/tabs/CommonView';

// @ts-ignore
window.Buffer = Buffer;

const App: React.FC = () => {
	let cookies: any = getInternalStorage() || {};
	const mounted = useRef(false);

	// Try to disable browser back button
	window.history.pushState(null, '', window.location.href);
	window.onpopstate = function () {
		window.history.pushState(null, '', window.location.href);
	};
	// -- end

	if (!cookies[LocalStorageKeys.Uuid]) {
		// clearInternalStorage();
	}

	const _navigator: any = window.navigator,
		isWebBrowser: Boolean = 'serviceWorker' in _navigator;

	if (!isWebBrowser) {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		async () => await StatusBar.hide();

		SplashScreen.show({
			showDuration: 0,
			autoHide: true,
		});
	} else {
		if (_navigator.vendor === 'Apple Computer, Inc.') {
			info('serviceWorker::register::registerValidSW: This is Safari');
			setInternalStorage(SessionStorageKeys.Safari, true);
		}
	}

	if (!cookies[LocalStorageKeys.Uuid]) {
		for (let cookie of Object.keys(cookies)) {
			if (!_.includes([SessionStorageKeys.DesktopNotifications, LocalStorageKeys.PersonalDevice, SessionStorageKeys.SM, LocalStorageKeys.DB, LocalStorageKeys.DBVersion, SessionStorageKeys.Version], cookie)) {
				deleteItemFromInternalStorage(cookie);
			}
		}
	}

	if (!cookies[SessionStorageKeys.Listeners]) {
		cookies[SessionStorageKeys.Listeners] = [];
	}

	if (isWebBrowser) {
		cookies[SessionStorageKeys.MobileDevice] = /Mobi|Android/i.test(navigator.userAgent);
		cookies[SessionStorageKeys.DisplayMode] = ('standalone' in _navigator && _navigator?.standalone === true) || window.matchMedia('(display-mode: standalone)').matches ? 'standalone' : 'browser';
	} else {
		cookies[SessionStorageKeys.MobileDevice] = true;
		cookies[SessionStorageKeys.DisplayMode] = 'standalone';
	}

	cookies[SessionStorageKeys.Network] = NetworkStatus.ONLINE;

	cookies[SessionStorageKeys.IsApp] = !isWebBrowser;
	setInternalStorage(SessionStorageKeys.DisplayMode, cookies[SessionStorageKeys.DisplayMode]);
	// setInternalStorage(SessionStorageKeys.MobileDevice, cookies[SessionStorageKeys.MobileDevice]);

	if (cookies[LocalStorageKeys.Verfication]) {
		setInternalStorage(LocalStorageKeys.Verfication, cookies[LocalStorageKeys.Verfication]);
	}

	setInternalStorage(LocalStorageKeys.DB, cookies[LocalStorageKeys.DB]);
	setInternalStorage(SessionStorageKeys.IsApp, cookies[SessionStorageKeys.IsApp]);
	setInternalStorage(SessionStorageKeys.Listeners, cookies[SessionStorageKeys.Listeners]);
	setInternalStorage(SessionStorageKeys.DesktopNotifications, !isWebBrowser ? false : cookies[SessionStorageKeys.DesktopNotifications]);
	setInternalStorage(SessionStorageKeys.Network, window.navigator.onLine ? NetworkStatus.ONLINE : NetworkStatus.OFFLINE);
	const verficationStatus = localStorage.getItem(LocalStorageKeys.Verfication);
	useEffect(() => {
		if (!mounted.current) {
			mounted.current = true;
			setTimeout(() => {
				SharedService.loadReduxInitialStateForLoggedInUser();

				if (verficationStatus !== 'verified') {
				}
			}, 200);
		}
	}, [mounted, verficationStatus]);

	if (!sharedService.rootHistory) {
		sharedService.rootHistory = createBrowserHistory();
	}
	return (
		<Provider store={store}>
			<IonApp>
				<CommonView />
				<IonReactRouter history={sharedService.rootHistory}>
					<IonRouterOutlet animated={false}>
						{/* <Route path="*" component={NoMatch as any} /> */}
						{/* <Route path="/testscripts" component={TestReport as any} exact={true} /> */}
						{/*<!-- <Route path="/vv" component={() => <VV />} exact={true} /> -->*/}
						{/*<!-- <Route path="/Receivervv" component={() => <ReceiverVV />} exact={true} /> -->*/}
						{/*<!-- <Route path="/call" component={() => <Call />} exact={true} /> -->*/}
						{mainRoutes.map((item, key) => (
							<Route key={key} exact={item.exact} path={item.path} component={item.component as any} />
						))}
						<Route exact path="/">
							{verficationStatus === 'verified' ? <Redirect to={NavigationRoutes.LOGIN} /> : <Redirect to={NavigationRoutes.VERIFICATIONS_REQUIRED} />}
						</Route>
					</IonRouterOutlet>
				</IonReactRouter>
			</IonApp>
		</Provider>
	);
};

// function switchTheme(shouldAdd: boolean) {
//   document.body.classList.toggle('dark', shouldAdd)
// }

export default App;
