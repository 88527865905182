import React, { useRef } from 'react';
import { IonItem, IonIcon, IonLabel, IonItemSliding, IonText } from '@ionic/react';
import './style.scss';
import { notificationsOffOutline } from 'ionicons/icons';
import moment from 'moment';
import ConversationProfilePhoto from './ConversationProfilePhoto';
import ConversationItemOptions from './ConversationItemOptions';
import { locale } from '../../../locales/local';
import { isBlank } from '../../../helpers/common';
import _ from 'lodash';
import { ChatMediaType, ChatType, MessageType } from '../../../services/enumService';
import SharedService from '../../../services/sharedService';
import { Message } from '../../../types/message';

const ConversationItem: React.FC<any> = ({ index, conversation, chatClickHandler }) => {
	const ionItemSlideRef = useRef(null);
	let message: any = '',
		lastMessage: Message = conversation?.lastMessage,
		prefix: boolean | string = false;

	if (lastMessage) {
		if (lastMessage?.body || !isBlank(lastMessage?.media)) {
			if (lastMessage?.type === ChatType.GROUP) {
				prefix = `${lastMessage.from}`;
			}

			if (lastMessage?.recalled) {
				if (lastMessage?.from === 'Me') {
					message = <span dangerouslySetInnerHTML={{ __html: `${locale.dashboard.thread.message_recalled}` }}></span>;
				} else {
					message = <span dangerouslySetInnerHTML={{ __html: `${locale.dashboard.thread.recalled_a_message}` }}></span>;
				}
			} else if (!lastMessage?.translated) {
				let messageBody = '';
				if (lastMessage.body) {
					messageBody = SharedService.decodeSpecialCharInStr(lastMessage.body);
				}

				message = <span dangerouslySetInnerHTML={{ __html: `${message}${messageBody}` }}></span>;
			} else {
				let messageBody = '';
				// if (lastMessage.translation) {
				// 	messageBody = SharedService.decodeSpecialCharInStr(lastMessage.translation);
				// }

				message = <span dangerouslySetInnerHTML={{ __html: `${message}${messageBody}` }}></span>;
			}

			if (lastMessage?.messageType === MessageType.MEDIA) {
				message = `[${lastMessage.media?.contentType.includes(ChatMediaType.IMAGE) ? 'Photo' : 'Video'}] `;
			} else if (lastMessage?.messageType === MessageType.FILE) {
				message = lastMessage.media?.contentType.includes(ChatMediaType.AUDIO) ? '[Audio]' : lastMessage.media?.contentType.includes(ChatMediaType.VIDEO) ? '[Video]' : `[File]`;
			} else if (lastMessage?.messageType === MessageType.CONTACT) {
				message = `[Contact] `;
			} else if (lastMessage?.messageType === MessageType.GIF) {
				message = `[GIF] `;
			} else if (lastMessage?.messageType === MessageType.LOCATION) {
				message = `[Location] `;
			}
		}
	} else {
		// if no last message then displayed greeting message
		message = <span dangerouslySetInnerHTML={{ __html: `${conversation.message || 'No message'}` }}></span>;
	}

	let messageDateTime;
	if (lastMessage && lastMessage.originalTimestamp) {
		messageDateTime = moment(lastMessage.datetime);
	} else if (conversation.created) {
		messageDateTime = moment(conversation.created);
	}

	const msgSentTime = messageDateTime ? messageDateTime.calendar(null, { sameDay: 'h:mm A', lastDay: '[Yesterday]', lastWeek: 'dddd', sameElse: 'M/D/YYYY' }) : '';

	return (
		<IonItemSliding className="conversation-item-sliding" ref={ionItemSlideRef} key={conversation._id}>
			<IonItem lines="none" onClick={chatClickHandler} detail={false} button={true}>
				<ConversationProfilePhoto conversation={conversation} />
				<IonLabel>
					<div className="h-row firstline">
						<h3 className="chatname">{conversation.mucName || conversation.alias || conversation.userId || conversation.userInfo.profileName}</h3>
						<div className="timestamp">{msgSentTime}</div>
					</div>
					<div className="h-row secondline">
						<div className="chatlastmessage">
							{lastMessage?.messageType === MessageType.CONTACT ? <IonText color="primary">You shared {lastMessage.body.profileName}</IonText> : prefix && <IonText color="primary">{prefix}</IonText>}
							{lastMessage?.messageType === MessageType.LOCATION ? <IonText color="primary">You shared {lastMessage.body.profileName}</IonText> : prefix && <IonText color="primary">{prefix}</IonText>}
							{message ? message : 'No message'}
						</div>
						{index === -1 && <IonIcon className="mute-icon" slot="end" icon={notificationsOffOutline}></IonIcon>}
					</div>
				</IonLabel>
			</IonItem>
			<ConversationItemOptions ionItemSlideRef={ionItemSlideRef} />
		</IonItemSliding>
	);
};

export default ConversationItem;
