import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IonButton, IonButtons, IonCheckbox, IonIcon, IonLoading, IonModal, IonSlide, IonSlides, IonTitle, IonToolbar } from '@ionic/react';
import './MultipleImageEditor.scss';
import { locale } from '../../locales/local';
// Import styles and functionalities from `pintura`
import 'pintura/pintura.css';
import { cameraService } from '../../services/cameraService';

import {
	// editor
	locale_en_gb,
	createDefaultImageReader,
	createDefaultImageWriter,
	createDefaultShapePreprocessor,
	// plugins
	setPlugins,
	plugin_crop,
	plugin_crop_locale_en_gb,
	plugin_finetune,
	plugin_finetune_locale_en_gb,
	plugin_finetune_defaults,
	plugin_filter,
	plugin_filter_locale_en_gb,
	plugin_filter_defaults,
	plugin_decorate,
	markup_editor_defaults,
	plugin_decorate_locale_en_gb,
	markup_editor_locale_en_gb,
	getEditorDefaults,
	plugin_sticker,
	plugin_sticker_locale_en_gb,
	degToRad,
	createMarkupEditorToolbar,
	createMarkupEditorToolStyle,
	createMarkupEditorToolStyles,
} from 'pintura';
import SharedService, { sharedService } from '../../services/sharedService';
import { add } from 'ionicons/icons';
import { Image } from '../../components/Image';
import { ChatMediaType } from '../../services/enumService';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../redux/actions/global';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { fabric } from 'fabric';
import EmojiSearchModal from '../EmojiSearchModal/EmojiSearchModal';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ScketchpadTextStylePopover from '../../components/ScketchpadTextStylePopover/ScketchpadTextStylePopover';

const RotateIcon = './assets/icon/photo-rorate-icon.svg',
	CloseIcon = './assets/icon/close-icon-outline.svg',
	DeleteIcon = './assets/icon/delete-icon.svg',
	VideoPlayer = './assets/icon/video-player.svg',
	PhotoEditSmileIcon = './assets/icon/photo-edit-smile-icon.svg',
	PhotoEditTextIcon = './assets/icon/photo-edit-text-icon.svg',
	PhotoEditCropIcon = './assets/icon/photo-edit-crop-icon.svg',
	PhotoEditSquareIcon = './assets/icon/photo-edit-square-icon.svg',
	PhotoEditDrawIcon = './assets/icon/photo-edit-draw-icon.svg',
	RevertIcon = './assets/icon/revert-icon.svg',
	TextNormalIcon = './assets/icon/text-normal-icon.svg',
	TextSelectedIcon = './assets/icon/text-selected-icon.svg',
	TextStyleIcon = './assets/icon/text-style-selected.svg',
	editorColors = ['#FFFFFF', '#000000', '#FF3B30', '#FFCC4D', '#FF8300', '#04DB19', '#00A3FF', '#FF00C7'],
	textStyles = [
		{
			title: 'Thin',
			value: 'thin',
			style: {
				fontStyle: 'normal',
				fontWeight: 100,
				fontSize: '16px',
				lineHeight: '16px',
			},
		},
		{
			title: 'Regular',
			value: 'regular',
			style: {
				fontStyle: 'normal',
				fontWeight: 400,
				fontSize: '18px',
				lineHeight: '18px',
			},
		},
		{
			title: 'Semi Bold',
			value: 'semiBold',
			style: {
				fontStyle: 'normal',
				fontWeight: 600,
				fontSize: '18px',
				lineHeight: '18px',
			},
		},
		{
			title: 'Bold',
			value: 'bold',
			style: {
				fontStyle: 'normal',
				fontWeight: 700,
				fontSize: '18px',
				lineHeight: '18px',
			},
		},
		{
			title: 'Black',
			value: 'black',
			style: {
				fontStyle: 'normal',
				fontWeight: 800,
				fontSize: '18px',
				lineHeight: '18px',
			},
		},
		{
			title: 'Thin Italic',
			value: 'thinItalic',
			style: {
				fontStyle: 'italic',
				fontWeight: 100,
				fontSize: '18px',
				lineHeight: '18px',
			},
		},
		{
			title: 'Regular Italic',
			value: 'regularItalic',
			style: {
				fontStyle: 'italic',
				fontWeight: 400,
				fontSize: '18px',
				lineHeight: '18px',
			},
		},
		{
			title: 'Semi Bold Italic',
			value: 'semiBoldItalic',
			style: {
				fontStyle: 'italic',
				fontWeight: 600,
				fontSize: '18px',
				lineHeight: '18px',
			},
		},
		{
			title: 'Bold Italic',
			value: 'boldItalic',
			style: {
				fontStyle: 'italic',
				fontWeight: 700,
				fontSize: '18px',
				lineHeight: '18px',
			},
		},
		{
			title: 'Black Italic',
			value: 'blackItalic',
			style: {
				fontStyle: 'italic',
				fontWeight: 900,
				fontSize: '18px',
				lineHeight: '18px',
			},
		},
	];

export const OpenEditorType = {
	FOR_PROFILE_GROUP_PHOTO: 'for_profile_group_photo',
	FOR_SEND_IMAGE: 'for_send_image',
	FOR_EDIT_IMAGE: 'for_edit_image',
};

export const EditingType = {
	DRAWING: 'drawing',
	STICKER: 'sticker',
	ORIGINAL: 'text',
	CROP: 'crop',
	RECTANGLE: 'rectangle',
};

interface iProps {
	show: boolean;
	openEditorType?: string;
	medias: any;
	outputFormat?: 'file' | 'imageData' | 'canvas' | undefined;
	onClose?: any;
	onSave?: any;
}

const MultipleImageEditor: React.FC<iProps> = ({ openEditorType, outputFormat = 'file', show, medias, onClose, onSave }) => {
	const [showModal, setShowModal] = useState(false);
	const [croppedFile, setCroppedFile] = useState<File>();

	const { editor, onReady } = useFabricJSEditor();

	const dispatch = useDispatch();

	function getWindowDimensions() {
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height,
		};
	}
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		if (show) {
			setShowModal(true);
		}
	}, [show]);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	let screenWidth = windowDimensions;
	let screenHeight = windowDimensions.height - 186;

	setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_decorate, plugin_sticker);

	let isManuallySlideCHanging = false;

	const processDefaults = {
			imageReader: createDefaultImageReader(),
			imageWriter: createDefaultImageWriter({
				mimeType: 'image/webp',
				quality: 1,
				format: outputFormat,
			}),
		},
		editorDefaults = {
			...getEditorDefaults(),
			...processDefaults,
			enableButtonRevert: true,
			enableButtonExport: false,
			...plugin_finetune_defaults,
			...plugin_filter_defaults,
			...markup_editor_defaults,
			locale: {
				...locale_en_gb,
				...plugin_crop_locale_en_gb,
				...plugin_finetune_locale_en_gb,
				...plugin_filter_locale_en_gb,
				...plugin_decorate_locale_en_gb,
				...markup_editor_locale_en_gb,
				...plugin_sticker_locale_en_gb,
			},
			// shapePreprocessor: createDefaultShapePreprocessor(),
		},
		sliderRef = useRef<HTMLIonSlidesElement>(null),
		[isImageProcessing, setIsImageProcessing] = useState(false),
		[selectedMediaIndex, setSelectedMediaIndex] = useState<number>(0),
		[mediasList, setMediasList] = useState<Array<File>>(medias),
		[fullSizeValues, setFullSizeValues] = useState<any>([]),
		[isHideEditor, setIsHideEditor] = useState(false),
		[isUndoButtonDisabled, setIsUndoButtonDisabled] = useState(true),
		[editorActiveColor, setEditorActiveColor] = useState(editorColors[0]),
		[currentEditingType, setCurrentEditingType] = useState(''),
		[loaded, setLoaded] = useState(false),
		[textSettings, setTextSettings] = useState({
			color: '#fff',
			backgroundColor: 'transparent',
			textAlign: 'left',
			textStyle: 'regular',
			backgroundEnabled: false,
		}),
		[isEmojiModalShow, setIsEmojiModalShow] = useState(false),
		[selectedTextStyle, setSelectedTextStyle] = useState(textStyles[1]),
		[textBgChangeActive, setTextBgChangeActive] = useState(false),
		[crop, setCrop] = useState<Crop>(),
		[completedCrop, setCompletedCrop] = useState<PixelCrop>(),
		[scale, setScale] = useState(1),
		[rotate, setRotate] = useState(0),
		[aspect, setAspect] = useState<number | undefined>(16 / 9),
		[historyArr, setHistoryArr] = useState<any>([]),
		[showTextStylePopover, setShowTextStylePopover] = useState(false),
		[textStylePopoverEvent, setTextStylePopoverEvent] = useState(null),
		[isChangesMade, setIsChangesMade] = useState(false);

	const imgRef = useRef<HTMLImageElement>(null),
		pinturaRef: any = {};
	const blobUrlRef = useRef('');
	const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);

	if (openEditorType === OpenEditorType.FOR_PROFILE_GROUP_PHOTO) {
		editorDefaults.imageCropAspectRatio = 1;
		editorDefaults.enableButtonRevert = true;
		editorDefaults.enableToolbar = false;
		editorDefaults.enableUtils = false;
		editorDefaults.enableButtonClose = false;
		editorDefaults.enableButtonExport = false;
		editorDefaults.enableNavigateHistory = false;
		editorDefaults.cropEnableButtonFlipHorizontal = false;
		editorDefaults.cropEnableButtonRotateLeft = false;
		editorDefaults.cropEnableZoomInput = false;
		editorDefaults.cropEnableRotationInput = false;
		editorDefaults.cropImageSelectionCornerStyle = 'hook';
		editorDefaults.utils = ['crop', 'filter', 'finetune', 'annotate', 'decorate', 'frame', 'redact', 'resize'];
	} else if (openEditorType === OpenEditorType.FOR_SEND_IMAGE || openEditorType === OpenEditorType.FOR_EDIT_IMAGE) {
		editorDefaults.imageCropAspectRatio = 0;
		editorDefaults.enableButtonRevert = false;
		editorDefaults.enableUtils = true;
		editorDefaults.enableToolbar = false;
		editorDefaults.cropEnableButtonFlipHorizontal = false;
		editorDefaults.cropEnableButtonRotateLeft = false;
		editorDefaults.cropEnableZoomInput = false;
		editorDefaults.cropEnableZoomInput = false;
		editorDefaults.cropEnableRotationInput = false;
		editorDefaults.stickerStickToImage = true;
		editorDefaults.annotateEnableButtonFlipVertical = false;
		editorDefaults.shapePreprocessor = createDefaultShapePreprocessor();
		editorDefaults.class = '';

		// editorDefaults.utils = ['crop', 'filter', 'finetune', 'annotate', 'decorate', 'frame', 'redact', 'resize'];
		editorDefaults.utils = ['decorate', 'crop', 'filter', 'frame', 'sticker'];
		editorDefaults.markupEditorToolbar = createMarkupEditorToolbar([]); //'sharpie', 'text', 'rectangle'

		// Default
		if (currentEditingType === EditingType.DRAWING) {
			editorDefaults.decorateActiveTool = 'sharpie';
			editorDefaults.markupEditorToolStyles = createMarkupEditorToolStyles({
				sharpie: createMarkupEditorToolStyle('path', {
					strokeColor: SharedService.hexToRgb('#ffffff'),
					strokeWidth: 5,
				}),
			});
		}
	}

	editorDefaults.willRenderCanvas = (shapes, state) => {
		const { utilVisibility, selectionRect, lineColor } = state;
		// exit if crop utils is not visible
		if (utilVisibility.crop <= 0) return shapes;

		// shortcuts to selection rect
		const { x, y, width, height } = selectionRect,
			partVertical = height / 3,
			partHorizontal = width / 3;
		// return updated shapes
		return {
			// copy props from shapes param
			...shapes,
			// add an `ellipse` shape
			interfaceShapes: [
				...shapes.interfaceShapes,

				{
					x1: x,
					y1: y + partVertical,
					x2: x + width,
					y2: y + partVertical,
					// opacity: utilVisibility.crop,
					inverted: true,
					// backgroundColor: [...backgroundColor, 0.5],
					strokeWidth: 1,
					strokeColor: [...lineColor],
				},
				{
					x1: x,
					y1: y + partVertical * 2,
					x2: x + width,
					y2: y + partVertical * 2,
					// opacity: utilVisibility.crop,
					inverted: true,
					// backgroundColor: [...backgroundColor, 0.5],
					strokeWidth: 1,
					strokeColor: [...lineColor],
				},
				{
					x1: x + partHorizontal,
					y1: y,
					x2: x + partHorizontal,
					y2: y + height,
					// opacity: utilVisibility.crop,
					inverted: true,
					// backgroundColor: [...backgroundColor, 0.5],
					strokeWidth: 1,
					strokeColor: [...lineColor],
				},
				{
					x1: x + partHorizontal * 2,
					y1: y,
					x2: x + partHorizontal * 2,
					y2: y + height,
					// opacity: utilVisibility.crop,
					inverted: true,
					// backgroundColor: [...backgroundColor, 0.5],
					strokeWidth: 1,
					strokeColor: [...lineColor],
				},
			],
		};
	};

	useEffect(() => {
		if (mediasList && mediasList.length > 0) {
			if (!fullSizeValues || fullSizeValues.length === 0) {
				setFullSizeValues(mediasList.map((item: any, key: any) => fullSizeValues[key] || false));
			}
		}
	}, [mediasList, fullSizeValues]);

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				// const element: any = document.querySelector("[title='Revert']");
				// if (element) {
				//  var observer = new MutationObserver((mutations) => {
				//      mutations?.forEach((mutation) => {
				//          if (mutation.type === 'attributes') {
				//              setIsUndoButtonDisabled(element.disabled);
				//          }
				//      });
				//  });
				//  if (observer) {
				//      observer.observe(element, {
				//          attributes: true, //configure it to listen to attribute changes
				//      });
				//  }
				// }
			}, 4000);

			setTimeout(() => {
				setLoaded(true);
			}, 500);
		} else {
			setLoaded(false);
			setIsUndoButtonDisabled(true);
			setCurrentEditingType('');
			setSelectedMediaIndex(0);
			setMediasList([]);
			setFullSizeValues([]);
		}
	}, [show]);

	const RevertButton = (
		<>
			<IonButton
				className="undo-btn"
				fill="clear"
				slot="icon-only"
				onClick={() => {
					currentEditingType === EditingType.ORIGINAL && setCurrentEditingType('');
					if (historyArr.length > 1) {
						const lastHistoryItem = historyArr.pop();
						if (lastHistoryItem.type === 'object') {
							if (editor?.canvas) {
								const canvas = editor?.canvas;
								var currentlySelected = lastHistoryItem.value;
								if (currentlySelected) {
									canvas.remove(currentlySelected);
								}
							}
						} else if (lastHistoryItem.type === 'background') {
							let colorToBeChange = '#ffffff';

							if (historyArr.length > 1) {
								const lastColorObject = historyArr[historyArr.length - 1];
								if (lastColorObject.type === 'background') {
									colorToBeChange = lastColorObject.value;
								} else {
									colorToBeChange = '#fff';
									historyArr.forEach((element: any) => {
										if (element.type === 'background') {
											colorToBeChange = element.value;
										}
									});
								}
							}

							if (editor?.canvas) {
								const canvas: any = editor?.canvas;
								canvas.setBackgroundColor(colorToBeChange, canvas.requestRenderAllBound);
							}
						}

						setHistoryArr(historyArr);
					}
				}}
			>
				<IonIcon className="revertIcon" src={RevertIcon} />
			</IonButton>
		</>
	);

	const closeCallback = useCallback(() => {
		if (currentEditingType) {
			setHistoryArr(() => {
				historyArr.splice(0, historyArr.length);
				return historyArr;
			});
			setCurrentEditingType('');
		} else {
			setShowModal(false);
		}
	}, [currentEditingType, historyArr]);

	const activateDrawingMode = useCallback(
		(canvas) => {
			setIsUndoButtonDisabled(false);
			if (canvas) {
				canvas.freeDrawingBrush.color = editorColors[0];
				canvas.freeDrawingBrush.width = 5;
				canvas.isDrawingMode = true;
			}
		},
		[pinturaRef]
	);
	const activateTextMode = useCallback(
		(canvas) => {
			canvas.isDrawingMode = false;
			const xPos = 30;
			const yPos = 170;
			var text = new fabric.Textbox('', {
				cursorColor: 'red',
				fontSize: 30,
				fontWeight: selectedTextStyle.style.fontWeight,
				fontStyle: selectedTextStyle.style.fontStyle as any,
				fontFamily: 'Metropolis',
				top: yPos,
				left: xPos,
				width: window.innerWidth - xPos * 2,
				fill: '#fff',
			});
			canvas.add(text);
			canvas.setActiveObject(text);
			text.enterEditing();
			text.hiddenTextarea?.focus();
			canvas.requestRenderAll();

			fabric.Object.prototype.setControlsVisibility({
				ml: false,
				mr: false,
				mb: false,
				mt: false,
			});
		},
		[selectedTextStyle]
	);
	const activateCropMode = useCallback((canvas, imgUrl) => {
		// fabric.Image.fromURL(imgUrl, function (oImg: any) {
		//  oImg.scale(0.7);
		//  oImg.top = 50;
		//  oImg.left = 50;
		//  oImg.cropX = 100;
		//  oImg.cropY = 100;
		//  oImg.cropWidth = 500;
		//  oImg.cropHeight = 500;
		//  canvas.add(oImg);
		//  canvas.renderAll();
		// });
		// fabric.Image.fromURL(imgUrl, function (oImg: any) {
		//  let currentImage = oImg;
		//  // init canvas
		//  const canvas = initCnvas();
		//  canvas.preserveObjectStacking = true;
		//  addImage(canvas);
		//  createMaskForCrop(canvas);
		//  crop(canvas);
		//  function initCnvas() {
		//      return new fabric.Canvas('c', {
		//          width: 1200,
		//          height: 600,
		//          strokeWidth: 5,
		//          stroke: 'rgba(100,200,200,0.5)',
		//      });
		//  }
		//  function addImage(canvas: any) {
		//      const dogImg = new Image();
		//      dogImg.src = 'dog.jpg';
		//      dogImg.onload = function () {
		//          const img = new fabric.Image(dogImg);
		//          canvas.add(img);
		//          canvas.centerObject(img);
		//          canvas.setActiveObject(img);
		//          currentImage = img;
		//          canvas.renderAll();
		//      };
		//  }
		//  function createMaskForCrop(canvas: any) {
		//      //  After click start crop add the mask to canvas
		//      document.querySelector('#startCrop').addEventListener('click', function () {
		//          // Create mask layer and show to canvas
		//          addSelectionRect();
		//          canvas.setActiveObject(selectionRect);
		//          canvas.renderAll();
		//          document.querySelector('#crop').style.display = 'block';
		//      });
		//  }
		//  function addSelectionRect() {
		//      let selectionRect = new fabric.Rect({
		//          fill: 'rgba(0,0,0,0.3)',
		//          originX: 'left',
		//          originY: 'top',
		//          stroke: 'black',
		//          opacity: 1,
		//          width: currentImage.width,
		//          height: currentImage.height,
		//          hasRotatingPoint: false,
		//          transparentCorners: false,
		//          cornerColor: 'white',
		//          cornerStrokeColor: 'black',
		//          borderColor: 'black',
		//          cornerSize: 12,
		//          padding: 0,
		//          cornerStyle: 'circle',
		//          borderDashArray: [5, 5],
		//          borderScaleFactor: 1.3,
		//      });
		//      selectionRect.scaleToWidth(300);
		//      canvas.centerObject(selectionRect);
		//      canvas.add(selectionRect);
		//  }
		//  function crop(canvas: any) {
		//      // Click the crop button croped the masked area
		//      document.querySelector('#crop').addEventListener('click', function (event) {
		//          document.querySelector('button#crop').style.display = 'none';
		//          let selectionrect;
		//          // create mask rectabgle for crop
		//          let rect = new fabric.Rect({
		//              left: selectionRect.left,
		//              top: selectionRect.top,
		//              width: selectionRect.getScaledWidth(),
		//              height: selectionRect.getScaledHeight(),
		//              absolutePositioned: true,
		//          });
		//          // add to the current image clicpPath property
		//          currentImage.clipPath = rect;
		//          // remove the mask layer
		//          canvas.remove(selectionRect);
		//          // init new image instance
		//          var cropped = new Image();
		//          // set src value of canvas croped area as toDataURL
		//          cropped.src = canvas.toDataURL({
		//              left: rect.left,
		//              top: rect.top,
		//              width: rect.width,
		//              height: rect.height,
		//          });
		//          // after onload clear the canvas and add cropped image to the canvas
		//          cropped.onload = function () {
		//              let image;
		//              canvas.clear();
		//              image = new fabric.Image(cropped);
		//              image.left = rect.left;
		//              image.top = rect.top;
		//              image.setCoords();
		//              canvas.add(image);
		//              canvas.renderAll();
		//          };
		//      });
		//  }
		// });
	}, []);

	const onCanvasReady = useCallback(
		(canvas, imgUrl, editingType) => {
			let devicePixelRatio = window.devicePixelRatio > 1 ? 1 : window.devicePixelRatio;

			fabric.Image.fromURL(imgUrl, (img: any) => {
				console.log('historyArr', historyArr);
				(historyArr.length === 0 || currentEditingType === EditingType.ORIGINAL) && canvas.add(img);
				const editorContainerEl = document.getElementById('sliderContainerMIE');
				const containerHeight = editorContainerEl?.clientHeight || window.innerHeight;
				const containerWidth = window.innerWidth;

				const aspectRatio = img.width / img.height;

				let imgWidth = img.width;
				let imgHeight = img.height;

				// If image size is less then available screen size
				if (imgWidth < containerWidth && imgHeight < containerHeight) {
					if (imgWidth < containerWidth) {
						imgWidth = containerWidth;
						imgHeight = containerWidth / aspectRatio;
					}

					if (imgHeight < containerHeight) {
						imgWidth = containerHeight * aspectRatio;
						imgHeight = containerHeight;
					}
				}
				// If image size is greater then available screen size

				if (imgWidth > containerWidth || imgHeight > containerHeight) {
					if (imgWidth > containerWidth) {
						imgWidth = containerWidth;
						imgHeight = containerWidth / aspectRatio;
					}

					if (imgHeight > containerHeight) {
						imgWidth = containerHeight * aspectRatio;
						imgHeight = containerHeight;
					}
				}
				const top = (containerHeight - imgHeight) / 2;

				img.set({
					left: 0,
					top: 0,
					selectable: false,
				});
				img.scale(imgWidth / img.width);

				canvas.cacheCanvasEl.style.top = top + 'px';

				canvas.setDimensions({
					width: imgWidth * devicePixelRatio,
					height: imgHeight * devicePixelRatio,
				});
				canvas.renderAll();

				editingType === EditingType.DRAWING && activateDrawingMode(canvas);
				editingType === EditingType.STICKER && activateEmojiMode(canvas);
				editingType === EditingType.ORIGINAL && activateTextMode(canvas);
				// editingType === EditingType.CROP && activateCropMode(canvas, imgUrl);
			});
			onReady(canvas);

			canvas.setBackgroundColor('#ffffff', canvas.requestRenderAllBound);

			canvas.on('after:render', () => {
				// info('after:render');
				if (historyArr.length > 1) {
					setIsChangesMade(true);
				} else {
					setIsChangesMade(false);
				}
			});
			canvas.on('object:added', (object: any) => {
				// info('object:added');
				historyArr.push({ type: 'object', value: object.target });
				setHistoryArr(historyArr);
			});
			canvas.on('selection:cleared', () => {
				// info('selection:cleared');
				// setIsDeleteButtonShow(false);
			});
			canvas.on('selection:created', (event: any) => {
				// info('selection:created');
				// updateControlVisibility(canvas);
				// setIsDeleteButtonShow(true);
			});
			canvas.on('selection:updated', (event: any) => {
				// updateControlVisibility(canvas);
				// info('selection:updated');
			});

			// Controls UI customise
			fabric.Object.prototype.transparentCorners = false;
			fabric.Object.prototype.cornerColor = '#ffffff';
			fabric.Object.prototype.cornerStrokeColor = '#FF5E5E';
			fabric.Object.prototype.borderColor = '#FF5E5E';
			// fabric.Object.prototype.strokeWidth = controlsStrokeWidth;
			fabric.Object.prototype.cornerStyle = 'circle';
			// fabric.Object.prototype.cornerSize = controlsCornerSize;
		},
		[activateDrawingMode, onReady, activateTextMode, activateCropMode, historyArr]
	);

	const activateEmojiMode = useCallback((canvas) => {
		if (canvas) {
			canvas.isDrawingMode = false;
			setIsEmojiModalShow(true);
		}
	}, []);

	const onEmojiClick = useCallback(
		(event, emojiObj) => {
			setIsEmojiModalShow(false);
			// setIsDeleteButtonShow(true);

			if (editor?.canvas) {
				const canvas = editor?.canvas;
				fabric.Object.prototype.objectCaching = false;

				const xPos = (pinturaRef.current?.clientWidth || window.innerWidth) / 2;
				const yPos = (pinturaRef.current?.clientHeight || window.innerHeight) / 2;
				var text = new fabric.Text(emojiObj.emoji, {
					fontSize: 60,
					top: yPos,
					left: xPos,
				});
				canvas.add(text);
				canvas.setActiveObject(text);

				text.setControlsVisibility({
					ml: false,
					mr: false,
					mb: false,
					mt: false,
					// mtr:false,
				});
				canvas.requestRenderAll();
			}
		},
		[editor, pinturaRef]
	);

	const updateColor = useCallback(
		(color) => {
			switch (currentEditingType) {
				case EditingType.DRAWING:
					if (editor?.canvas) {
						const canvas = editor?.canvas;
						canvas.freeDrawingBrush.color = color;
					}
					break;
				case EditingType.ORIGINAL:
					if (editor?.canvas) {
						const canvas = editor?.canvas;
						fabric.Object.prototype.objectCaching = false;

						var currentlySelected = canvas.getActiveObject();
						if (currentlySelected) {
							if (textBgChangeActive) {
								currentlySelected.backgroundColor = color;
							} else {
								currentlySelected.fill = color;
							}
							canvas.requestRenderAll();
						}
					}
					break;

				default:
					break;
			}
		},
		[editor, currentEditingType, textBgChangeActive]
	);

	const stopEditing = useCallback(() => {
		if (historyArr.length > 1) {
			setHistoryArr(() => {
				historyArr.splice(0, historyArr.length);
				return historyArr;
			});
		}
		if (currentEditingType === EditingType.CROP && croppedFile) {
			mediasList[selectedMediaIndex] = croppedFile;
			setMediasList([...mediasList]);
		} else {
			const canvas = editor?.canvas;
			if (canvas) {
				const downlaodImg = canvas.toDataURL({
					format: 'jpeg',
					quality: 0.95,
					multiplier: 1,
				});
				console.log('downlaodImg', downlaodImg);
				var file = SharedService.dataURLtoFile(downlaodImg, 'updatedimage.png');

				mediasList[selectedMediaIndex] = file;
				setMediasList([...mediasList]);
			}

			// setIsEditing(false);
			setCurrentEditingType('');
		}
	}, [editor, currentEditingType, croppedFile, mediasList, selectedMediaIndex, historyArr]);

	const onTextStyleChange = useCallback(
		(item) => {
			if (editor?.canvas) {
				const canvas = editor?.canvas;
				fabric.Object.prototype.objectCaching = false;

				var currentlySelected: any = canvas.getActiveObject();
				if (currentlySelected) {
					currentlySelected.fontWeight = item.style.fontWeight;
					currentlySelected.fontStyle = item.style.fontStyle;
					canvas.requestRenderAll();
				}
			}
		},
		[editor]
	);

	return (
		<IonModal key="multipleImageEditorModal" isOpen={showModal} onDidDismiss={onClose} swipeToClose={false} showBackdrop={false} cssClass="multiple-image-editor-modal">
			<div className="modal-content-container">
				{(openEditorType === OpenEditorType.FOR_SEND_IMAGE || openEditorType === OpenEditorType.FOR_EDIT_IMAGE) && (
					<>
						<IonToolbar className="top-toolbar">
							<IonButtons slot="start">
								{currentEditingType !== EditingType.ORIGINAL ? (
									<IonButton fill="clear" slot="icon-only" onClick={closeCallback}>
										<IonIcon src={CloseIcon}></IonIcon>
									</IonButton>
								) : (
									historyArr.length > 1 && RevertButton
								)}
							</IonButtons>

							<IonTitle>
								{currentEditingType === EditingType.ORIGINAL && (
									<IonButton
										className="text-style-button"
										fill="clear"
										onClick={(event: any) => {
											setShowTextStylePopover(true);
											setTextStylePopoverEvent(event);
										}}
									>
										<IonIcon src={TextStyleIcon}></IonIcon>
										<div className="text">{selectedTextStyle.title}</div>
									</IonButton>
								)}

								{currentEditingType === '' && (
									<IonButton
										className="full-image-btn"
										fill="clear"
										onClick={() => {
											fullSizeValues[selectedMediaIndex] = !fullSizeValues[selectedMediaIndex];
											setFullSizeValues([...fullSizeValues]);
										}}
									>
										Full Size
										<IonCheckbox checked={fullSizeValues ? fullSizeValues[selectedMediaIndex] : false} />
									</IonButton>
								)}
							</IonTitle>

							<IonButtons slot="end">
								{currentEditingType === '' && (
									<IonButton
										className="add-more-media-btn"
										fill="clear"
										onClick={() => {
											cameraService.chooseMediaFromAlbum((medias: Array<File>) => {
												const newList = [...mediasList, ...medias];
												if (newList.length > sharedService.maxMediaSelectLimit) {
													dispatch(
														showAlert({
															alertMessage: `Select a Maximum of ${sharedService.maxMediaSelectLimit} photos`,
														})
													);
												} else {
													setMediasList(newList);
													setSelectedMediaIndex(newList.length - 1);
												}
											}, true);
										}}
									>
										<IonIcon src={add} />
									</IonButton>
								)}

								{(currentEditingType === EditingType.DRAWING || currentEditingType === EditingType.STICKER) && historyArr.length > 1 && RevertButton}
								{currentEditingType === EditingType.ORIGINAL && (
									<IonButton
										fill="clear"
										disabled={!isChangesMade}
										className="send-btn"
										onClick={() => {
											stopEditing();
											setCurrentEditingType('');
											const slidesElement = sliderRef.current;
											slidesElement?.lockSwipes(false);
											pinturaRef[selectedMediaIndex]?.editor?.processImage();
										}}
									>
										Done
									</IonButton>
								)}
							</IonButtons>
						</IonToolbar>
					</>
				)}

				<div
					className={'editor-container'}
					onClick={() => {
						setIsHideEditor(!isHideEditor);
					}}
				>
					{currentEditingType === '' && mediasList && mediasList.length > 1 && (
						<div className="media-list-container">
							{mediasList &&
								mediasList.map((media: File, key: any) => {
									return (
										<div
											className={'item ' + (selectedMediaIndex === parseInt(key) ? 'active' : '')}
											key={key}
											onClick={() => {
												isManuallySlideCHanging = true;
												const slidesElement = sliderRef.current;
												if (slidesElement) {
													slidesElement.slideTo(parseInt(key), 0).then(() => {
														isManuallySlideCHanging = false;
													});
												}
												setSelectedMediaIndex(parseInt(key));
											}}
										>
											{media.type.includes(ChatMediaType.IMAGE) && (
												<>
													<Image file={media} />
													<div className="mask-view" />
												</>
											)}
											{media.type.includes(ChatMediaType.VIDEO) && (
												<>
													<video>
														<source src={URL.createObjectURL(media)}></source>
													</video>
													<div className="mask-view" />
													<IonIcon className="video-player-icon" src={VideoPlayer} />
												</>
											)}
										</div>
									);
								})}
						</div>
					)}

					{mediasList && mediasList.length > 0 && (
						<div className="slider-container" id="sliderContainerMIE">
							<IonSlides
								ref={sliderRef}
								options={{ initialSlide: selectedMediaIndex }}
								key={mediasList.map((item: File, key: any) => key).join('_')}
								onIonSlideDidChange={(event) => {
									if (!isManuallySlideCHanging) {
										const target: any = event.target;
										target.getActiveIndex().then((value: any) => setSelectedMediaIndex(value));
									}
								}}
							>
								{mediasList.map((media: File, key: any) => {
									return (
										<IonSlide key={key}>
											<div className="slider-div">
												{/* <IonImg src={media} /> */}
												{loaded && (
													<>
														{media.type.indexOf('video') !== -1 ? (
															<>
																<video controls width={screenWidth.width} height={screenHeight}>
																	<source src={URL.createObjectURL(media)}></source>
																</video>
															</>
														) : (
															<>
																{(currentEditingType === EditingType.DRAWING || currentEditingType === EditingType.STICKER || currentEditingType === EditingType.ORIGINAL) && key === selectedMediaIndex ? (
																	currentEditingType === EditingType.RECTANGLE ? (
																		<ReactCrop crop={crop} onChange={(c) => setCrop(c)} minWidth={100} minHeight={100}>
																			<img
																				ref={imgRef}
																				alt="Crop me"
																				src={URL.createObjectURL(media)}
																				style={{
																					width: screenWidth.width,
																					height: screenWidth.height,
																					transform: `scale(${scale}) rotate(${rotate}deg)`,
																				}}
																			/>
																		</ReactCrop>
																	) : (
																		// })
																		<FabricJSCanvas
																			className="sketchpad-canvas"
																			onReady={(canvas) => {
																				onCanvasReady(canvas, URL.createObjectURL(media), currentEditingType);
																			}}
																		/>
																	)
																) : (
																	<>
																		<img src={URL.createObjectURL(media)} />
																	</>
																)}
																{/* <PinturaEditor
                                                                    // disabled={currentEditingType === ''}
                                                                    {...editorDefaults}
                                                                    className="editor"
                                                                    ref={(ref) => {
                                                                        pinturaRef[key] = ref;
                                                                    }}
                                                                    src={media}
                                                                    onUpdateshape={() => {}}
                                                                    onRevert={() => {
                                                                        document.getElementById('undoBtn')?.setAttribute('disabled', 'true');
                                                                    }}
                                                                    onLoad={(res: any) => {
                                                                        pinturaRef[selectedMediaIndex]?.editor?.on('update', () => {
                                                                            setIsUndoButtonDisabled(false);
                                                                            document.getElementById('undoBtn')?.setAttribute('disabled', 'false');
                                                                        });
                                                                    }}
                                                                    onProcess={(result: any) => {
                                                                        const file = result?.dest;
                                                                        setIsImageProcessing(false);

                                                                        mediasList[selectedMediaIndex] = file;
                                                                        setMediasList([...mediasList]);

                                                                        pinturaRef[selectedMediaIndex]?.editor?.close && pinturaRef[selectedMediaIndex].editor.close();
                                                                        // onSave && onSave(file, isFullImage);
                                                                    }}
                                                                /> */}
															</>
														)}
													</>
												)}
											</div>
										</IonSlide>
									);
								})}
							</IonSlides>
						</div>
					)}
				</div>

				{openEditorType === OpenEditorType.FOR_PROFILE_GROUP_PHOTO && (
					<div className="bottom-view-for-croponly">
						<div className="tool-containers">
							<IonButton
								className="rotate-icon"
								fill="clear"
								slot="icon-only"
								onClick={() => {
									const editorRef = pinturaRef[selectedMediaIndex]?.editor;
									if (editorRef) {
										editorRef.imageRotation = (editorRef.imageRotation || 0) - degToRad(90);
									}
								}}
							>
								<IonIcon src={RotateIcon} />
							</IonButton>
						</div>
						<IonToolbar className="save-cancel-btns">
							<IonButtons slot="start">
								<IonButton
									slot="end"
									className="action-btn edit-cancel-btn"
									onClick={() => {
										pinturaRef[selectedMediaIndex]?.editor?.close && pinturaRef[selectedMediaIndex].editor.close();
										closeCallback();
									}}
								>
									{locale.global.cancel}
								</IonButton>
							</IonButtons>
							<IonTitle>
								<IonButton
									disabled={isUndoButtonDisabled}
									fill="clear"
									mode="ios"
									id="undoBtn"
									className="action-btn undo-btn"
									onClick={() => {
										pinturaRef[selectedMediaIndex]?.editor?.history?.revert();
									}}
								>
									{locale.global.undo}
								</IonButton>
							</IonTitle>
							{/* <IonButtons slot="end">
                                <IonButton
                                    className="action-btn edit-save-btn"
                                    slot="end"
                                    onClick={() => {
                                        setIsImageProcessing(true);
                                        pinturaRef[selectedMediaIndex]?.editor?.processImage();
                                    }}
                                >
                                    {locale.global.done}
                                </IonButton>
                            </IonButtons> */}
						</IonToolbar>
					</div>
				)}

				{(openEditorType === OpenEditorType.FOR_SEND_IMAGE || openEditorType === OpenEditorType.FOR_EDIT_IMAGE) && (
					<div className="bottom-tools-view">
						{currentEditingType === '' && mediasList && mediasList.length > 1 && (
							<div className="delte-btn-container">
								<IonButton
									fill="clear"
									onClick={() => {
										mediasList.splice(selectedMediaIndex, 1);
										fullSizeValues.splice(selectedMediaIndex, 1);
										if (selectedMediaIndex >= mediasList.length) {
											setSelectedMediaIndex(mediasList.length - 1);
										}
										setFullSizeValues([...fullSizeValues]);
										setMediasList([...mediasList]);
										if (mediasList.length === 0) {
											closeCallback();
										}
									}}
								>
									<IonIcon src={DeleteIcon} />
								</IonButton>
							</div>
						)}

						{currentEditingType === EditingType.DRAWING && (
							<div className="colors-container">
								{editorColors.map((color, key) => (
									<div
										onClick={() => {
											setEditorActiveColor(color);
											updateColor(color);
										}}
										className={'color-circle' + (editorActiveColor === color ? ' active' : '')}
										key={key}
										style={{ backgroundColor: color }}
									></div>
								))}
							</div>
						)}

						{currentEditingType === EditingType.ORIGINAL && (
							<div className="colors-container">
								<div
									className="t-select"
									onClick={() => {
										setTextBgChangeActive(!textBgChangeActive);
										textSettings.backgroundEnabled = !textSettings.backgroundEnabled;
										setTextSettings({ ...textSettings });
									}}
								>
									<IonIcon src={textSettings.backgroundEnabled ? TextSelectedIcon : TextNormalIcon} />
								</div>
								{editorColors.map((color, key) => (
									<div
										onClick={() => {
											setEditorActiveColor(color);
											updateColor(color);
										}}
										className={'color-circle' + (editorActiveColor === color ? ' active' : '')}
										key={key}
										style={{ backgroundColor: color }}
									></div>
								))}
							</div>
						)}

						<div className="container">
							<div className="edit-options">
								<IonButton
									fill="clear"
									className={'edit-type-btn drawing-btn' + (currentEditingType === EditingType.DRAWING || currentEditingType === '' ? ' active' : '')}
									onClick={() => {
										// if (openEditorType === OpenEditorType.FOR_SEND_IMAGE) {
										//  // const editor = pinturaRef[selectedMediaIndex]?.editor;
										//  const decorateElement: any = document.querySelector('li[id$="decorate"] button');
										//  decorateElement && decorateElement.click();

										//  const PinturaControlPanelsElement: any = document.querySelector('.PinturaUtilFooter');
										//  PinturaControlPanelsElement.style.opacity = 0;
										//  setTimeout(() => {
										//      const sharpieElement: any = document.querySelector('.PinturaControlListOption input[value="sharpie"]');
										//      sharpieElement && sharpieElement.click();
										//      sharpieElement && sharpieElement.focus();
										//      PinturaControlPanelsElement.style.opacity = 1;
										//  }, 100);
										// } else {
										// }
										if (currentEditingType === EditingType.DRAWING || currentEditingType === '') {
											const slidesElement = sliderRef.current;
											slidesElement?.lockSwipes(true);
											setCurrentEditingType(EditingType.DRAWING);
											if (editor?.canvas) {
												activateDrawingMode(editor?.canvas);
											}
										}
									}}
								>
									<IonIcon style={{ width: 20, height: 25 }} src={PhotoEditDrawIcon}></IonIcon>
								</IonButton>
								<IonButton
									fill="clear"
									className={'edit-type-btn' + (currentEditingType === EditingType.STICKER || currentEditingType === '' ? ' active' : '')}
									onClick={(canvas) => {
										if (currentEditingType === EditingType.STICKER || currentEditingType === '') {
											const slidesElement = sliderRef.current;
											slidesElement?.lockSwipes(true);
											setCurrentEditingType(EditingType.STICKER);
											if (editor?.canvas) {
												activateEmojiMode(editor?.canvas);
											}
										}
									}}
								>
									<IonIcon style={{ width: 25, height: 25 }} src={PhotoEditSmileIcon}></IonIcon>
								</IonButton>
								<IonButton
									fill="clear"
									className={'edit-type-btn' + (currentEditingType === EditingType.ORIGINAL || currentEditingType === '' ? ' active' : '')}
									onClick={() => {
										if (currentEditingType === EditingType.ORIGINAL || currentEditingType === '') {
											setCurrentEditingType(EditingType.ORIGINAL);
											if (editor?.canvas) {
												activateTextMode(editor?.canvas);
											}

											const slidesElement = sliderRef.current;
											slidesElement?.lockSwipes(true);
										}
									}}
								>
									<IonIcon style={{ width: 32, height: 24 }} src={PhotoEditTextIcon}></IonIcon>
								</IonButton>
								<IonButton
									fill="clear"
									className={'edit-type-btn' + (currentEditingType === EditingType.CROP || currentEditingType === '' ? ' active' : '')}
									onClick={() => {
										if (currentEditingType === EditingType.CROP || currentEditingType === '') {
											setCurrentEditingType(EditingType.CROP);
											const slidesElement = sliderRef.current;
											slidesElement?.lockSwipes(true);
											if (editor?.canvas) {
												activateCropMode(editor?.canvas, imgRef.current);
											}
										}
									}}
								>
									<IonIcon style={{ width: 25, height: 25 }} src={PhotoEditCropIcon}></IonIcon>
								</IonButton>
								<IonButton
									fill="clear"
									className={'edit-type-btn' + (currentEditingType === EditingType.RECTANGLE || currentEditingType === '' ? ' active' : '')}
									onClick={($event) => {
										setCurrentEditingType(EditingType.RECTANGLE);

										const slidesElement = sliderRef.current;
										slidesElement?.lockSwipes(true);
									}}
								>
									<IonIcon style={{ width: 22, height: 22 }} src={PhotoEditSquareIcon}></IonIcon>
								</IonButton>
								{/* <div>
                                    <IonInput id="txtScaling" type="text" onChange={(e) => setValue(e.target.addEventListener)}></IonInput>
                                    <IonButton
                                        fill="clear"
                                        className={'edit-type-btn' + (currentEditingType === EditingType.RECTANGLE || currentEditingType === '' ? ' active' : '')}
                                        onClick={($event) => {
                                            // if (openEditorType === OpenEditorType.FOR_SEND_IMAGE) {
                                            //  // const editor = pinturaRef[selectedMediaIndex]?.editor;
                                            //  const decorateElement: any = document.querySelector('li[id$="decorate"] button');
                                            //  decorateElement && decorateElement.click();

                                            //  const PinturaControlPanelsElement: any = document.querySelector('.PinturaUtilFooter');
                                            //  PinturaControlPanelsElement.style.opacity = 0;
                                            //  setTimeout(() => {
                                            //      const rectangleElement: any = document.querySelector('.PinturaControlListOption input[value="rectangle"]');
                                            //      rectangleElement && rectangleElement.click();
                                            //      rectangleElement && rectangleElement.focus();
                                            //      PinturaControlPanelsElement.style.opacity = 1;
                                            //  }, 100);
                                            // } else {
                                            // }
                                            // const editor = pinturaRef[selectedMediaIndex]?.editor;
                                            // editor.decorateActiveTool = 'rectangle';
                                            // setCurrentEditingType(EditingType.RECTANGLE);
                                            activatePixelateMode(editor?.canvas, imgRef.current);

                                            const slidesElement = sliderRef.current;
                                            slidesElement?.lockSwipes(true);
                                        }}
                                    >
                                        <IonIcon style={{ width: 22, height: 22 }} src={PhotoEditSquareIcon}></IonIcon>
                                    </IonButton>
                                </div> */}
							</div>

							{currentEditingType === '' ? (
								<IonButton
									fill="clear"
									className="send-btn"
									onClick={() => {
										// setIsImageProcessing(true);
										// pinturaRef[selectedMediaIndex]?.editor?.processImage();
										onSave && onSave(mediasList, fullSizeValues);
									}}
								>
									Send({mediasList?.length})
								</IonButton>
							) : (
								currentEditingType !== EditingType.ORIGINAL && (
									<IonButton
										fill="clear"
										disabled={!isChangesMade}
										className="send-btn"
										onClick={() => {
											stopEditing();
											setCurrentEditingType('');
											const slidesElement = sliderRef.current;
											slidesElement?.lockSwipes(false);
											pinturaRef[selectedMediaIndex]?.editor?.processImage();
										}}
									>
										Done
									</IonButton>
								)
							)}
						</div>
					</div>
				)}

				<IonLoading isOpen={isImageProcessing} message={'Processing'} />
				<EmojiSearchModal
					show={isEmojiModalShow}
					onCancel={() => {
						setIsEmojiModalShow(false);
					}}
					onEmojiClick={onEmojiClick}
				></EmojiSearchModal>
				<ScketchpadTextStylePopover
					showPopover={showTextStylePopover}
					selectedValue={selectedTextStyle}
					onDismiss={() => {
						setShowTextStylePopover(false);
						setTextStylePopoverEvent(null);
					}}
					onItemSelect={(item: any) => {
						setSelectedTextStyle(item);
						setShowTextStylePopover(false);
						setTextStylePopoverEvent(null);
						onTextStyleChange(item);
					}}
					popoverEvent={textStylePopoverEvent}
					list={textStyles}
				/>
			</div>
		</IonModal>
	);
};

export default MultipleImageEditor;
